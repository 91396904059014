import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

import fbRecommendations from "./fb-recommendations.svg"
import Stars from "./Stars"

import PropTypes from "prop-types"
import FeaturedSocial from "./FeaturedSocial"

var classNames = require("classnames")

class SocialReviews extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    var google, facebook, ratingTwoDigits

    let featuredSocialClasses = classNames({
      "featured-social": true,
      headless: !this.props.googleHeading
    })

    google = this.props.socialData
    ratingTwoDigits = google.rating.toFixed(1)

    return (
      <>
        {this.props.google && (
          <div className="social-reviews">
            <div
              className={featuredSocialClasses}
              style={{ paddingBottom: this.props.paddingBottom }}>
              {this.props.googleHeading && (
                <a
                  className="link-wrapper"
                  href={google.url}
                  target="_blank"
                  rel="noopener noreferrer">
                  <h3>Google</h3>
                </a>
              )}
              <a
                className="link-wrapper"
                href={google.url}
                target="_blank"
                rel="noopener noreferrer">
                <div className="review-icons">
                  <h4 className="google-rating">{ratingTwoDigits}</h4>
                  <Stars rating={google.rating} />
                </div>
              </a>
              <a
                className="review-number-text"
                href={google.url}
                target="_blank">
                {google.totalReviews}{" "}
                {this.props.language === "es" ? "Reseñas" : "Reviews"}
              </a>
            </div>
          </div>
        )}

        {this.props.facebook && (
          <div className="social-reviews">
            <div className="featured-social">
              <a
                className="link-wrapper"
                href={facebook.url}
                target="_blank"
                rel="noopener noreferrer">
                <h3>Facebook</h3>
              </a>
              <a
                className="link-wrapper"
                href={facebook.url}
                target="_blank"
                rel="noopener noreferrer">
                <div className="review-icons">
                  <div className="svg-wrapper">
                    <img src={fbRecommendations} alt="Facebook Reviews" />
                  </div>
                </div>
              </a>
              <a
                className="review-number-text"
                href={facebook.url}
                target="_blank">
                {facebook.totalReviews}{" "}
                {this.props.language === "es" ? "Reseñas" : "Reviews"}
              </a>
            </div>
          </div>
        )}
      </>
    )
  }
}

SocialReviews.propTypes = {
  language: PropTypes.string
}

export default SocialReviews
